import Image from 'next/image';
import ErrorImage from '@cvent/carina-glyphs/illustrations/hero-error-shrug.svg';
import { Button } from '@/components/shared/button/Button';
import { useEffect, useState } from 'react';
import { getCookie } from '@/utils/cookieHelper';
import { i18n, Locale, toPhraseLocale } from '@/i18n';

export type GlobalErrorStrings = {
  errorHeader: string;
  message: string;
  instanceId: string;
  time: string;
  tryAgain: string;
};

type Props = {
  translations: GlobalErrorStrings;
  reset: () => void;
  instanceId: string | undefined;
};

export default function ErrorDetail({ translations, reset, instanceId }: Props) {
  const [locale, setLocale] = useState<Locale | undefined>(undefined);

  useEffect(() => {
    const localeCookieData: Locale = getCookie('csn_search_locale') as Locale;
    setLocale(localeCookieData);
  }, []);

  return (
    <div className="-mt-3 flex flex-col items-center justify-center px-4 font-primary">
      <Image src={ErrorImage} alt="This isn't right" priority height={320} />
      <h1 className="mb-3 text-d-sm">{translations.errorHeader}</h1>
      <p className="mb-6 text-center text-neutral-60">{translations.message}</p>
      <div className="pb-6">
        <Button type="button" onClick={reset} text={translations.tryAgain} appearance="filled" />
      </div>
      <div className="flex flex-col gap-3 pb-8 text-center text-b-sm text-neutral-60">
        {instanceId && (
          <div>
            <p>{translations.instanceId}</p>
            <p>{instanceId}</p>
          </div>
        )}
        <div>
          <p>{translations.time}</p>
          <p>
            {new Intl.DateTimeFormat(toPhraseLocale(locale ?? i18n.defaultLocale), {
              dateStyle: 'full',
              timeStyle: 'medium'
            }).format(new Date())}
          </p>
        </div>
      </div>
    </div>
  );
}
