import { useContext } from 'react';
import { ClientDictionaryContext } from '@/providers/ClientDictionaryProvider';
import { type ClientDictionary } from '@/utils/clientDictionary';

/**
 * A hook for getting access to client translated dictionary
 */
export default function useTranslations(): ClientDictionary {
  return useContext<ClientDictionary>(ClientDictionaryContext);
}
