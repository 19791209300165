'use client';

import { useCallback, useEffect, useState } from 'react';
import useTranslations from '@/hooks/useTranslations';
import { v4 } from 'uuid';
import ErrorDetail from '@/components/ErrorDetail';
import useLogger from '@/hooks/useLogger';

// App's -> Error Page
export default function Error({ error }: { error: Error & { digest?: string } }): JSX.Element {
  const logger = useLogger('error.tsx');
  const [instanceId, setInstanceId] = useState<string | undefined>(undefined);
  const translations = useTranslations();
  const handleReset = useCallback(() => {
    window.location.reload();
  }, []);

  useEffect(() => {
    const errorId = v4();
    setInstanceId(errorId);
    logger.error('Failed to log error from Error.tsx. Error: %o', {
      error: {
        message: error.message,
        stack: error.stack
      }
    });
  }, [error, logger]);

  return <ErrorDetail reset={handleReset} translations={translations} instanceId={instanceId} />;
}
