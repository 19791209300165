'use client';

import type { ReactNode } from 'react';
import { createContext } from 'react';
import { type ClientDictionary } from '@/utils/clientDictionary';

interface Props {
  children: ReactNode;
  clientDictionary: ClientDictionary;
}

export const ClientDictionaryContext = createContext({} as ClientDictionary);

export default function ClientDictionaryProvider({ children, clientDictionary }: Props) {
  return <ClientDictionaryContext.Provider value={clientDictionary}>{children}</ClientDictionaryContext.Provider>;
}
