import 'client-only';

import { ComponentPropsWithoutRef, forwardRef, useMemo, memo } from 'react';
import { injectTestId } from '@cvent/nucleus-test-automation';
import type { ForwardedRef } from 'react';
import { tv } from 'tailwind-variants';
import { useComposedRef } from '@cvent/carina/hooks/useComposedRef';
import { Appearance, Icon, Variant } from './types';
import { ButtonShape, baseButton } from './Button.shape';
import type { CSX } from '../types/utlity/tailwindVariants';

const disabledTypeEnum = { STYLE_ONLY: 'style-only' } as const;

export type PolymorphicElementType = 'a' | 'button';

export type ElementProps<ELEMENT extends PolymorphicElementType> = Omit<
  ComponentPropsWithoutRef<ELEMENT>,
  keyof CommonProps<ELEMENT> | 'forwardedRef' | 'class'
>;

// type guard functions
const isAnchorButtonProps = (
  element: PolymorphicElementType,
  props: Record<string, unknown>
): props is ElementProps<'a'> => element === 'a';

const isButtonButtonProps = (
  element: PolymorphicElementType,
  props: Record<string, unknown>
): props is ElementProps<'button'> => !isAnchorButtonProps(element, props);

const iconAlignment = (icon?: Icon, iconAlign?: string) => {
  const alignment = iconAlign?.toLowerCase();
  return {
    iconBefore: icon && alignment === 'start' ? icon : undefined,
    iconAfter: icon && alignment === 'end' ? icon : undefined
  };
};

export interface CommonProps<ELEMENT extends PolymorphicElementType = 'button'> {
  /** Adds active state styles to button. Used when button is a trigger for menus, flyouts, or similar elements that have an open/active state. */
  active?: boolean;
  /** Applies styles that denote the button action's importance on the page. */
  appearance?: Appearance;
  /** Apply additional styles using tailwind variants formatted object. */
  csx?: CSX<ButtonShape>;
  /** Disables the button and changes the style appropriately. Providing the string "style-only" will allow interactions, including linking and pointer events. */
  disabled?: boolean | 'style-only';
  /** Determines what underlying element to use, button or anchor. If the on click action takes the user off of the page, you should pass in `a`, for an anchor link. Otherwise, leave it default, to have it be a button. */
  element?: ELEMENT;
  /** Icon to render inside the button. */
  icon?: Icon | JSX.Element;
  /** Positions the icon before or after the button text. */
  iconAlign?: 'start' | 'end';
  /** Allows button to fill 100% of the width of its parent container. */
  isBlock?: boolean;
  /** Applies a boxShadow and size increase on hover. When true, will force button to have appearance 'filled' and variant 'brand'. */
  isElevated?: boolean;
  /** True to apply specific styles for 'Floating Action Button'. When true, will force button to have appearance 'filled' and variant 'brand'. */
  isFAB?: boolean;
  /** Styles the button with fully rounded corners. */
  isPill?: boolean;
  /** Event handler called when button is clicked or when enter or space keys are pressed when button is focused. */
  onClick?: React.MouseEventHandler<ELEMENT extends 'a' ? HTMLAnchorElement : HTMLButtonElement>;
  /** Sets the size of the button. */
  size?: 's' | 'm' | 'l';
  /** ID used primarily for e2e tests (maps to `data-cvent-id`). */
  testID?: string;
  /** Text to display inside the button. */
  text?: string;
  /** Applies styles for the button variant. The following variants have been deprecated: accent, interactive, neutral, success, tertiary, and warning. */
  variant?: Variant;
  className?: string;
}

export type CarinaButtonProps<ELEMENT extends PolymorphicElementType = 'button'> = CommonProps<ELEMENT> &
  ElementProps<ELEMENT>;

/**
 * Opinionated Button element for use in Cvent-branded interfaces. Requires
 * at least one of `icon` or `text` and usually has an `onClick` or `onPress` event handler.
 */
export const Button = forwardRef(
  <ELEMENT extends PolymorphicElementType = 'button'>(
    {
      active,
      appearance: initialAppearance = 'lined',
      // csx,
      disabled,
      element = 'button' as ELEMENT,
      icon: iconProp,
      iconAlign = 'start',
      isBlock,
      isElevated,
      isFAB,
      isPill,
      onClick,
      size = 'm',
      testID,
      text,
      variant = 'brand',
      className,
      ...propDrop
    }: CarinaButtonProps<ELEMENT>,
    ref?: ForwardedRef<ELEMENT extends 'a' ? HTMLAnchorElement : HTMLButtonElement>
  ): JSX.Element => {
    const composedRef = useComposedRef(ref);
    const IconGlyph = iconProp as Icon;
    const { iconBefore, iconAfter } = iconAlignment(IconGlyph, iconAlign);

    const computedIconSize = useMemo(() => {
      if (IconGlyph && text) {
        return 'sm';
      }

      return isFAB ? 'lg' : 'md';
    }, [isFAB, text, IconGlyph]);

    const buttonStyle = tv({
      extend: baseButton
      // ...csx
    });

    const { base, icon } = buttonStyle({
      active,
      appearance: initialAppearance,
      block: isBlock,
      disabled,
      elevated: isElevated,
      fab: isFAB,
      iconAlign,
      iconOnly: IconGlyph && !text,
      pill: isPill,
      size,
      variant
    });

    const ButtonContent = (
      <>
        {iconBefore && (
          <span className={icon()} dir="ltr">
            {typeof IconGlyph === 'function' ? <IconGlyph size={computedIconSize} /> : IconGlyph}
          </span>
        )}
        {text && <span className="min-w-0 overflow-hidden text-ellipsis whitespace-nowrap">{text}</span>}
        {iconAfter && (
          <span className={icon()} dir="ltr">
            {typeof IconGlyph === 'function' ? <IconGlyph size={computedIconSize} /> : IconGlyph}
          </span>
        )}
      </>
    );

    let ButtonElement = <></>;

    if (isAnchorButtonProps(element, propDrop)) {
      const { href, ...rest } = propDrop;

      ButtonElement = (
        <a
          {...(rest as ComponentPropsWithoutRef<'a'>)}
          {...injectTestId(testID)}
          className={[base(), className].join(' ')}
          aria-hidden={rest['aria-hidden']}
          aria-label={rest['aria-label']}
          onClick={!disabled ? (onClick as React.MouseEventHandler<HTMLAnchorElement>) : undefined}
          href={disabled ? undefined : href}
          dir="ltr"
          ref={composedRef as React.Ref<HTMLAnchorElement>}
          aria-disabled={disabled === disabledTypeEnum.STYLE_ONLY || undefined}
        >
          {ButtonContent}
        </a>
      );
    }

    if (isButtonButtonProps(element, propDrop)) {
      const { ...rest } = propDrop;

      ButtonElement = (
        <button
          {...(rest as ComponentPropsWithoutRef<'button'>)}
          {...injectTestId(testID)}
          className={[base(), className].join(' ')}
          aria-hidden={rest['aria-hidden']}
          aria-label={rest['aria-label']}
          data-cvent-id={testID}
          disabled={disabled === true}
          dir="ltr"
          ref={composedRef as React.Ref<HTMLButtonElement>}
          type="button"
          onClick={!disabled ? (onClick as React.MouseEventHandler<HTMLButtonElement>) : undefined}
          aria-disabled={disabled === disabledTypeEnum.STYLE_ONLY || undefined}
        >
          {ButtonContent}
        </button>
      );
    }

    return ButtonElement;
  }
) as <ELEMENT extends PolymorphicElementType = 'button'>(
  props: CarinaButtonProps<ELEMENT> & {
    ref?: React.Ref<ELEMENT extends 'a' ? HTMLAnchorElement : HTMLButtonElement>;
  }
) => JSX.Element;

export default memo(Button);
