import { tv } from 'tailwind-variants';

export const buttonShape = {
  slots: {
    base: 'carina-button',
    icon: 'carina-button--icon'
  },
  variants: {
    active: {
      true: 'carina-button__active'
    },
    appearance: {
      filled: 'carina-button__filled',
      lined: 'carina-button__lined',
      ghost: 'carina-button__ghost'
    },
    block: {
      true: 'carina-button__block'
    },
    disabled: {
      true: 'carina-button__disabled',
      'style-only': 'carina-button__disabled'
    },
    elevated: {
      true: 'carina-button__elevated'
    },
    fab: {
      true: 'carina-button__fab'
    },
    iconAlign: {
      start: {
        icon: 'carina-button--icon__start'
      },
      end: {
        icon: 'carina-button--icon__end'
      }
    },
    iconOnly: {
      true: 'carina-button__icon-only'
    },
    pill: {
      true: 'carina-button__pill'
    },
    size: {
      s: 'carina-button__s',
      m: 'carina-button__m',
      l: 'carina-button__l'
    },
    variant: {
      danger: 'carina-button__danger',
      brand: 'carina-button__brand',
      'neutral-white': 'carina-button__neutral-white',
      'neutral-black': 'carina-button__neutral-black',
      menu: 'carina-button__menu'
    }
  }
};

export type ButtonShape = typeof buttonShape;

export const baseButton = tv(buttonShape);
